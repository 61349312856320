import CenterContainer from "components/CenterContainer";
import { observer } from "mobx-react";
import * as React from "react";
import { AppLinkFormDataInterface, appLinkStore } from "stores/AppLinkStore";
import { navigationStore } from "stores/NavigationStore";
import AppLinkForm from "../Form";

@observer
class CreateAppLinkPage extends React.Component<any> {
  onSubmit = async (data: AppLinkFormDataInterface) => {
    await appLinkStore.create(data);
    navigationStore.go("/app-links");
  };

  render() {
    return (
      <CenterContainer title="Opret nyt app link">
        <AppLinkForm onSubmit={this.onSubmit} />
      </CenterContainer>
    );
  }
}

export default CreateAppLinkPage;
