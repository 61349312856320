import DeepLinkSelector from "components/DeepLinkSelector";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { FieldState, FormState } from "formstate";
import {
  requiredValidator
} from "helpers/FormValidation";
import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "rsuite";
import { AppLinkFormDataInterface } from "stores/AppLinkStore";
import { loadingStore } from "stores/LoadingStore";
import { navigationStore } from "stores/NavigationStore";

interface Props {
  onSubmit: (data: AppLinkFormDataInterface) => void;
  onDuplicate?: (id: string) => void;
  editData?: AppLinkFormDataInterface;
  hideSaveButton?: boolean;
  onDelete?: () => void;
}

@observer
class AppLinkForm extends React.Component<Props, { previewUrl?: string }> {
  form: FormState<any> = new FormState({
    name: new FieldState("").validators(requiredValidator).disableAutoValidation(),
    appInternalLink: new FormState({
      linkType: new FieldState("").validators(requiredValidator),
      theme: new FieldState("").validators(
        (val) => (!val?.length || val === "-1") && this.form.$.appInternalLink.$.linkType?.value === "theme" && "Påkrævet"
      ),
      productOfferName: new FieldState("").validators(
        (val) => (!val?.length || val === "-1") && this.form.$.appInternalLink.$.linkType?.value === "product-offer-specific" && "Påkrævet"
      ),
      url: new FieldState("").validators(
        (val) =>
          !val?.length &&
          this.form.$.appInternalLink.$.linkType?.value !== undefined &&
          "Påkrævet"
      ),
    }),
  });

  constructor(props: Props) {
    super(props);
    this.state = { previewUrl: undefined };
  }

  componentDidMount() {
    if (this.props.editData) {
      const data = this.props.editData;
      this.form.$.name.reset(data.name);
      this.form.$.appInternalLink.$.url.reset(data.appInternalLink);
    }

    this.form.validate();
  }

  onSubmit = () => {
    const { onSubmit, editData } = this.props;
    const form = this.form.$;
    const data = {
      id: editData?.id ?? null,
      name: form.name.$,
      appInternalLink: form.appInternalLink.$.url.$,
    } as AppLinkFormDataInterface;
    onSubmit(data);
  };

  render() {
    const { hideSaveButton, onDelete } = this.props;
    const form = this.form.$;
    const isLoading = loadingStore.isLoading;

    return (
      <Form onValidSubmit={this.onSubmit} formState={this.form}>
        <Input
          disabled={hideSaveButton}
          label="Navn (*påkrævet)"
          fieldState={form.name}
        />

        <DeepLinkSelector
          readonly={hideSaveButton ?? false}
          formState={form.appInternalLink}
        />

        <div style={{ textAlign: "center", margin: "80px 0" }}>
          {!hideSaveButton && (
            <Button color="red" type="submit" loading={isLoading}>
              Gem link
            </Button>
          )}

          <Button
            style={!hideSaveButton ? { marginLeft: "20px" } : undefined}
            onClick={() => navigationStore.go("/app-links")}
          >
            Tilbage
          </Button>
        </div>

        {onDelete && !hideSaveButton && (
          <div style={{ textAlign: "center", marginBottom: "60px" }}>
            <Button
              appearance="link"
              style={{ color: "grey" }}
              onClick={onDelete}
            >
              Slet link
            </Button>
          </div>
        )}
      </Form>
    );
  }
}

export default AppLinkForm;
