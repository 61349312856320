import DeepLinkSelector from "components/DeepLinkSelector";
import DateInput from "components/Forms/DateInput";
import Form from "components/Forms/Form";
import Label from "components/Forms/Form/Label";
import Input from "components/Forms/Input";
import SelectReceiver from "components/SelectReceiver";
import SelectSegment from "components/SelectSegment";
import SelectStore from "components/SelectStore";
import { FieldState, FormState } from "formstate";
import {
  bannerFormatValidator,
  numbersOrSemicolonValidator,
  requiredValidator,
} from "helpers/FormValidation";
import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "rsuite";
import { BannerFormDataInterface } from "stores/BannerStore";
import { loadingStore } from "stores/LoadingStore";
import { navigationStore } from "stores/NavigationStore";

interface Props {
  onSubmit: (data: BannerFormDataInterface) => void;
  onDuplicate?: (id: string) => void;
  editData?: BannerFormDataInterface;
  hideSaveButton?: boolean;
  onDelete?: () => void;
}

@observer
class BannerForm extends React.Component<Props, { previewUrl?: string }> {
  form: FormState<any> = new FormState({
    title: new FieldState("")
      .validators(requiredValidator)
      .disableAutoValidation(),
    ctaText: new FieldState("").disableAutoValidation(),
    sortOrder: new FieldState("0").validators(requiredValidator),
    deepLink: new FormState({
      linkType: new FieldState("").validators(
        (val) => (!val?.length || val === "-1") && this.form.$.ctaText.value && "Påkrævet"
      ),
      theme: new FieldState("").validators(
        (val) => (!val?.length || val === "-1") && this.form.$.deepLink.$.linkType?.value === "theme" && "Påkrævet"
      ),
      productOfferName: new FieldState("").validators(
        (val) => (!val?.length || val === "-1") && this.form.$.deepLink.$.linkType?.value === "product-offer-specific" && "Påkrævet"
      ),
      url: new FieldState("").validators(
        (val) =>
          !val?.length &&
          this.form.$.deepLink.$.linkType?.value !== undefined &&
          this.form.$.ctaText.value &&
          "Påkrævet"
      ),
    }),
    file: new FieldState(undefined).validators(
      (val) =>
        !val && !this.form.$.existingFileUrl.value && "Banner er påkrævet",
      bannerFormatValidator
    ),
    existingFileUrl: new FieldState(undefined),
    receiverType: new FieldState("").validators(requiredValidator),
    stores: new FieldState([""]).validators(
      (val) =>
        !val.length &&
        this.form.$.receiverType.value === "segment-store" &&
        "Påkrævet"
    ),
    segments: new FieldState([""]).validators(
      (val) =>
        !val.length &&
        this.form.$.receiverType.value === "segment-store" &&
        "Påkrævet"
    ),
    phoneNumbers: new FieldState("").validators(
      (val) =>
        !val &&
        this.form.$.receiverType.value === "specific-customer" &&
        "Telefonnummer er påkrævet",
      numbersOrSemicolonValidator
    ),
    visibleFrom: new FieldState("").validators(requiredValidator),
    visibleTo: new FieldState("").validators(requiredValidator),
    published: new FieldState(false),
    noMemberCardOnly: new FieldState(false),
  });

  constructor(props: Props) {
    super(props);
    this.state = { previewUrl: undefined };
  }

  componentDidMount() {
    if (this.props.editData) {
      const data = this.props.editData;
      this.form.$.title.reset(data.title);
      this.form.$.ctaText.reset(data.ctaText);
      this.form.$.deepLink.$.url.reset(data.url);
      this.form.$.file.reset(undefined);
      this.form.$.sortOrder.reset(data.sortOrder.toString());
      this.form.$.existingFileUrl.reset(data.existingFileUrl);
      this.form.$.stores.reset(data.stores ?? []);
      this.form.$.segments.reset(data.segments ?? []);
      this.form.$.phoneNumbers.reset(data.phoneNumbers);
      this.form.$.receiverType.reset(data.receiverType);
      this.form.$.visibleFrom.reset(data.visibleFrom);
      this.form.$.visibleTo.reset(data.visibleTo);
      this.form.$.published.reset(data.published);
      this.form.$.noMemberCardOnly.reset(data.noMemberCardOnly);
    }

    this.form.validate();

    if (this.props.editData && this.props.editData.existingFileUrl) {
      this.setState(() => {
        return {
          previewUrl: this.props.editData
            ? this.props.editData.existingFileUrl
            : undefined,
        };
      });
    }
  }

  onSubmit = () => {
    const { onSubmit, editData } = this.props;
    const form = this.form.$;
    const data = {
      id: editData?.id ?? null,
      title: form.title.$,
      ctaText: form.ctaText.$,
      sortOrder: form.sortOrder.$,
      url: form.deepLink.$.url.$,
      file: form.file.$,
      existingFileUrl: form.existingFileUrl.$,
      receiverType: form.receiverType.$,
      stores: form.stores.$,
      segments: form.segments.$,
      phoneNumbers: form.phoneNumbers.$,
      published: form.published.$,
      noMemberCardOnly: form.noMemberCardOnly.$,
      visibleFrom: form.visibleFrom.$,
      visibleTo: form.visibleTo.$,
    } as BannerFormDataInterface;
    onSubmit(data);
  };

  displayPhoneNumberField = (disabled = false) => {
    const form = this.form.$;
    if (form.receiverType.value === "specific-customer") {
      return (
        <Input
          disabled={disabled}
          label="Telefonnummer - semikolon separeret for flere (*påkrævet)"
          type="text"
          fieldState={form.phoneNumbers}
        />
      );
    } else {
      return null;
    }
  };

  displayNoMemberCardOnlyField = (disabled = false) => {
    return (
      <div style={{ marginTop: "1rem" }}>
        <input
          type="checkbox"
          name="no_member_card_only"
          id="no_member_card_only"
          disabled={disabled}
          checked={this.form.$.noMemberCardOnly.value}
          onChange={(e: any) =>
            this.form.$.noMemberCardOnly.onChange(
              !this.form.$.noMemberCardOnly.value
            )
          }
        />
        <label htmlFor="no_member_card_only" style={{ marginLeft: ".5rem" }}>
          Vis kun banner for brugere uden medlemskort
        </label>
      </div>
    );
  };

  displaySegmentField = (disabled = false) => {
    const form = this.form.$;
    if (form.receiverType.value === "segment-store") {
      return (
        <div style={{ clear: "both", overflow: "hidden" }}>
          <h2 className="checkbox-group-heading">Segmenter</h2>
          <p style={{ marginBottom: 10 }}>Mindst ét segment er påkrævet*</p>
          <SelectSegment
            disabled={disabled}
            checkedItems={form.segments.value}
            fieldState={form.segments}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  displayStoreField = (disabled = false) => {
    const form = this.form.$;
    if (form.receiverType.value === "segment-store") {
      return (
        <div style={{ clear: "both", overflow: "hidden" }}>
          <h2 className="checkbox-group-heading">Butikker</h2>
          <p style={{ marginBottom: 10 }}>Mindst én butik er påkrævet*</p>
          <SelectStore
            disabled={disabled}
            checkedItems={form.stores.value}
            fieldState={form.stores}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  displayImageSection = () => {
    const form = this.form.$;
    if (form.existingFileUrl.value) {
      return (
        <Button
          style={{ marginTop: 20 }}
          type="button"
          onClick={this.onClickChangeFile}
        >
          Udskift billede
        </Button>
      );
    } else {
      return (
        <div>
          <Label
            htmlFor="image"
            label={
              "Billede (*pråkrævet). Maks 1MB, maks 1920x1080px og 16:9 format"
            }
            errorMessage={form.file.error}
          />
          <input
            style={{ marginTop: 10 }}
            id="image"
            type="file"
            accept="image/png, image/jpeg"
            onChange={this.onChangeFileInput}
          />
        </div>
      );
    }
  };

  onChangeFileInput = (e: any) => {
    const file = e.target.files[0];
    this.form.$.file.reset(file);
    this.form.$.file.validate();
    const url = file ? URL.createObjectURL(file) : undefined;
    this.setState(() => {
      return {
        previewUrl: url,
      };
    });
  };

  onClickChangeFile = () => {
    const form = this.form.$;
    this.setState({ previewUrl: undefined });
    form.existingFileUrl.value = undefined;
  };

  render() {
    const { hideSaveButton, editData, onDelete, onDuplicate } = this.props;
    const form = this.form.$;
    const isLoading = loadingStore.isLoading;
    let previewImage;

    if (this.state.previewUrl) {
      previewImage = (
        <img
          width={300}
          style={{ cursor: "pointer" }}
          alt="thumbnail"
          onClick={() => window.open(this.state.previewUrl)}
          src={this.state.previewUrl}
        />
      );
    } else {
      previewImage = undefined;
    }

    return (
      <Form onValidSubmit={this.onSubmit} formState={this.form}>
        <SelectReceiver
          disabled={hideSaveButton}
          fieldState={form.receiverType}
        ></SelectReceiver>

        {this.displayPhoneNumberField(hideSaveButton)}
        {this.displaySegmentField(hideSaveButton)}
        {this.displayStoreField(hideSaveButton)}
        {this.displayNoMemberCardOnlyField(hideSaveButton)}

        <Input
          disabled={hideSaveButton}
          label="Overskrift (*påkrævet)"
          fieldState={form.title}
        />

        <Input
          disabled={hideSaveButton}
          label="Knap tekst (valgfrit. Hvis feltet er tomt vises knap ikke i app'en)"
          fieldState={form.ctaText}
        />

        <Input
          type="number"
          disabled={hideSaveButton}
          label="Sortering (*påkrævet)"
          fieldState={form.sortOrder}
        />

        {this.displayImageSection()}

        <div style={{ marginTop: "20px" }}>{previewImage}</div>

        <br />

        <DeepLinkSelector
          readonly={hideSaveButton ?? false}
          formState={form.deepLink}
        />

        <DateInput
          enabledInPast={true}
          disabled={hideSaveButton}
          label="Start-tidspunkt (*påkrævet)"
          placeholder="Vælg"
          fieldState={form.visibleFrom}
        />

        <DateInput
          enabledInPast={true}
          disabled={hideSaveButton}
          label="Slut-tidspunkt (*påkrævet)"
          placeholder="Vælg"
          fieldState={form.visibleTo}
        />

        <div style={{ margin: "20px 0" }}>
          <label htmlFor="published">Udgivet</label>
          <input
            style={{ margin: "0 0 0 5px" }}
            id="published"
            type="checkbox"
            name="store"
            disabled={hideSaveButton}
            checked={form.published.value}
            onChange={(e: any) =>
              form.published.onChange(!form.published.value)
            }
          />
        </div>

        <div style={{ textAlign: "center", margin: "80px 0" }}>
          {!hideSaveButton && (
            <Button color="red" type="submit" loading={isLoading}>
              Gem banner
            </Button>
          )}

          <Button
            style={!hideSaveButton ? { marginLeft: "20px" } : undefined}
            onClick={() => navigationStore.go("/banners")}
          >
            Tilbage
          </Button>

          {onDuplicate && (
            <Button
              style={{ marginLeft: "20px" }}
              appearance="ghost"
              onClick={() => onDuplicate(editData?.id)}
            >
              Duplikér
            </Button>
          )}
        </div>

        {onDelete && !hideSaveButton && (
          <div style={{ textAlign: "center", marginBottom: "60px" }}>
            <Button
              appearance="link"
              style={{ color: "grey" }}
              onClick={onDelete}
            >
              Slet banner
            </Button>
          </div>
        )}
      </Form>
    );
  }
}

export default BannerForm;
