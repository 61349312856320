import DeepLinkSelector from "components/DeepLinkSelector";
import DateInput from "components/Forms/DateInput";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import SelectNotificationIcon from "components/SelectNotificationIcon";
import SelectPushType from "components/SelectPushType";
import SelectReceiver from "components/SelectReceiver";
import SelectSegment from "components/SelectSegment";
import SelectStore from "components/SelectStore";
import { FieldState, FormState } from "formstate";
import {
  numbersOrSemicolonValidator,
  requiredValidator,
} from "helpers/FormValidation";
import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "rsuite";
import { loadingStore } from "stores/LoadingStore";
import { navigationStore } from "stores/NavigationStore";

export interface NotificationFormDataInterface {
  title?: any;
  message?: any;
  url?: any;
  receiverType: "all" | "segment-store" | "specific-customer";
  storeIds?: string[];
  segmentIds?: string[];
  phoneNumber?: string;
  questionId?: any;
  notification_icon_type?: any;
  scheduleTime?: any;
  id?: any;
  pushType?: any;
  published: boolean;
  showPopup: boolean;
}
interface Props {
  onSubmit: (data: NotificationFormDataInterface) => void;
  onDuplicate?: (id: string) => void;
  editData?: NotificationFormDataInterface;
  hideSaveButton?: boolean;
  onDelete?: () => void;
}

@observer
class NotificationForm extends React.Component<Props> {
  form: FormState<any> = new FormState({
    title: new FieldState("")
      .validators(requiredValidator)
      .disableAutoValidation(),
    message: new FieldState("")
      .validators(requiredValidator)
      .disableAutoValidation(),
    deepLink: new FormState({
      linkType: new FieldState(""),
      theme: new FieldState("").validators(
        (val) =>
          (!val?.length || val === "-1") &&
          this.form.$.deepLink.$.linkType?.value === "theme" &&
          "Påkrævet"
      ),
      productOfferName: new FieldState("").validators(
        (val) => (!val?.length || val === "-1") && this.form.$.deepLink.$.linkType?.value === "product-offer-specific" && "Påkrævet"
      ),
      url: new FieldState("").validators(
        (val) =>
          !val?.length &&
          this.form.$.deepLink.$.linkType?.value !== undefined &&
          "Påkrævet"
      ),
    }),
    receiverType: new FieldState("").validators(requiredValidator),
    phoneNumber: new FieldState("").validators(
      (val) =>
        !val &&
        this.form.$.receiverType.value === "specific-customer" &&
        "Telefonnummer er påkrævet",
      numbersOrSemicolonValidator
    ),
    storeIds: new FieldState([""]).validators(
      (val) =>
        !val.length &&
        this.form.$.receiverType.value === "segment-store" &&
        "Påkrævet"
    ),
    segmentIds: new FieldState([""]).validators(
      (val) =>
        !val.length &&
        this.form.$.receiverType.value === "segment-store" &&
        "Påkrævet"
    ),
    questionId: new FieldState(""),
    notification_icon_type: new FieldState(""),
    scheduleTime: new FieldState(""),
    pushType: new FieldState(""),
    published: new FieldState(false),
    showPopup: new FieldState(false),
  });

  componentDidMount() {
    if (this.props.editData) {
      const data = this.props.editData;
      this.form.$.title.reset(data.title);
      this.form.$.message.reset(data.message);
      this.form.$.deepLink.$.url.reset(data.url);
      this.form.$.storeIds.reset(data.storeIds ?? []);
      this.form.$.segmentIds.reset(data.segmentIds ?? []);
      this.form.$.phoneNumber.reset(data.phoneNumber);
      this.form.$.receiverType.reset(data.receiverType);
      this.form.$.questionId.reset(data.questionId);
      this.form.$.notification_icon_type.reset(data.notification_icon_type);
      this.form.$.scheduleTime.reset(data.scheduleTime);
      this.form.$.pushType.reset(data.pushType);
      this.form.$.published.reset(data.published);
      this.form.$.showPopup.reset(data.showPopup);
    }
  }

  onSubmit = () => {
    const { onSubmit, editData } = this.props;
    const data = {
      id: editData?.id ?? null,
      title: this.form.$.title.$,
      message: this.form.$.message.$,
      url: this.form.$.deepLink.$.url.$,
      scheduleTime: this.form.$.scheduleTime.$ || new Date(),
      receiverType: this.form.$.receiverType.$,
      storeIds: this.form.$.storeIds.$,
      segmentIds: this.form.$.segmentIds.$,
      phoneNumber: this.form.$.phoneNumber.$,
      pushType: this.form.$.pushType.$ || false,
      questionId: this.form.$.questionId.$,
      notification_icon_type: this.form.$.notification_icon_type.$,
      published: this.form.$.published.$,
      showPopup: this.form.$.showPopup.$,
    } as NotificationFormDataInterface;

    onSubmit(data);
  };

  displayPhoneNumberField = (disabled = false) => {
    if (this.form.$.receiverType.value === "specific-customer") {
      return (
        <Input
          disabled={disabled}
          label="Telefonnummer - semikolon separeret for flere (*påkrævet)"
          type="text"
          fieldState={this.form.$.phoneNumber}
        />
      );
    } else {
      return null;
    }
  };

  displaySegmentField = (disabled = false) => {
    if (this.form.$.receiverType.value === "segment-store") {
      return (
        <div style={{ clear: "both", overflow: "hidden" }}>
          <h2 className="checkbox-group-heading">Segmenter</h2>
          <p style={{ marginBottom: 10 }}>Mindst ét segment er påkrævet*</p>
          <SelectSegment
            disabled={disabled}
            checkedItems={this.form.$.segmentIds.value}
            fieldState={this.form.$.segmentIds}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  displayStoreField = (disabled = false) => {
    if (this.form.$.receiverType.value === "segment-store") {
      return (
        <div style={{ clear: "both", overflow: "hidden" }}>
          <h2 className="checkbox-group-heading">Butikker</h2>
          <p style={{ marginBottom: 10 }}>Mindst én butik er påkrævet*</p>
          <SelectStore
            disabled={disabled}
            checkedItems={this.form.$.storeIds.value}
            fieldState={this.form.$.storeIds}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { hideSaveButton, editData, onDelete, onDuplicate } = this.props;
    const isLoading = loadingStore.isLoading;

    return (
      <Form onValidSubmit={this.onSubmit} formState={this.form}>
        <SelectReceiver
          disabled={hideSaveButton}
          fieldState={this.form.$.receiverType}
        ></SelectReceiver>

        {this.displayPhoneNumberField(hideSaveButton)}
        {this.displaySegmentField(hideSaveButton)}
        {this.displayStoreField(hideSaveButton)}

        <Input
          disabled={hideSaveButton}
          label="Overskrift (*påkrævet)"
          fieldState={this.form.$.title}
        />

        <Input
          disabled={hideSaveButton}
          label="Besked (*påkrævet)"
          type="textarea"
          fieldState={this.form.$.message}
        />

        <DeepLinkSelector
          readonly={hideSaveButton ?? false}
          formState={this.form.$.deepLink}
        />

        <SelectNotificationIcon
          disabled={hideSaveButton}
          fieldState={this.form.$.notification_icon_type}
        />

        <DateInput
          disabled={hideSaveButton}
          label="Dato (*påkrævet)"
          placeholder="Nu (hurtigst muligt)"
          fieldState={this.form.$.scheduleTime}
        />

        <SelectPushType
          disabled={hideSaveButton}
          fieldState={this.form.$.pushType}
        />

        <div style={{ margin: "20px 0" }}>
          <label htmlFor="show_modal">Vis popup</label>
          <input
            style={{ margin: "0 0 0 5px" }}
            id="show_modal"
            type="checkbox"
            name="store"
            disabled={hideSaveButton}
            checked={this.form.$.showPopup.value}
            onChange={(e: any) =>
              this.form.$.showPopup.onChange(!this.form.$.showPopup.value)
            }
          />
        </div>

        <div style={{ margin: "20px 0" }}>
          <label htmlFor="published">Udgivet</label>
          <input
            style={{ margin: "0 0 0 5px" }}
            id="published"
            type="checkbox"
            name="store"
            disabled={hideSaveButton}
            checked={this.form.$.published.value}
            onChange={(e: any) =>
              this.form.$.published.onChange(!this.form.$.published.value)
            }
          />
        </div>

        <div style={{ textAlign: "center", margin: "80px 0" }}>
          {!hideSaveButton && (
            <Button color="red" type="submit" loading={isLoading}>
              Gem notifikation
            </Button>
          )}

          <Button
            style={!hideSaveButton ? { marginLeft: "20px" } : undefined}
            onClick={() => navigationStore.go("/")}
          >
            Tilbage
          </Button>

          {onDuplicate && (
            <Button
              style={{ marginLeft: "20px" }}
              appearance="ghost"
              onClick={() => onDuplicate(editData?.id)}
            >
              Duplikér
            </Button>
          )}
        </div>

        {onDelete && !hideSaveButton && (
          <div style={{ textAlign: "center", marginBottom: "60px" }}>
            <Button
              appearance="link"
              style={{ color: "grey" }}
              onClick={onDelete}
            >
              Slet notifikation
            </Button>
          </div>
        )}
      </Form>
    );
  }
}

export default NotificationForm;
