import Dropdown from "components/Forms/Dropdown";
import Input from "components/Forms/Input";
import { FormState } from "formstate";
import _ from "lodash";
import { observer } from "mobx-react";
import * as React from "react";

interface State {
  themeId: string;
  productOfferName: string;
}

interface Props {
  readonly: boolean;
  formState: FormState<any>;
}

@observer
class DeepLinkSelector extends React.Component<Props, State> {
  themeOptions: any[] = [];
  productOfferOptions: any[] = [];
  linkTypes = [
    {
      label: 'Linker til "Indkøbsliste"',
      value: "shoppingList",
      url: "https://dgfs-butik.dk/shopping-list",
    },
    {
      label: 'Linker til "Inspiration"',
      value: "inspiration",
      url: "https://dgfs-butik.dk/inspiration",
    },
    {
      label: 'Linker til "Medlemskort"',
      value: "membercard",
      url: "https://dgfs-butik.dk/membercard",
    },
    {
      label: 'Linker til "Nyheder"',
      value: "news",
      url: "https://dgfs-butik.dk/news",
    },
    {
      label: 'Linker til "Profil"',
      value: "profile",
      url: "https://dgfs-butik.dk/profile",
    },
    {
      label: 'Linker til specifik "Tema-side"',
      value: "theme",
      url: "https://dgfs-butik.dk/theme/[theme.id]",
    },
    {
      label: 'Linker til "Tilbud"',
      value: "product-offers",
      url: "https://dgfs-butik.dk/product-offers",
    },
    {
      label: 'Linker til specifik "Tilbuds-gruppe"',
      value: "product-offer-specific",
      url: "https://dgfs-butik.dk/product-offers?product_group=[productOfferName]",
    },
    {
      label: 'Linker til "Find butik"',
      value: "stores",
      url: "https://dgfs-butik.dk/stores",
    },
    {
      label: 'Linker til "Marketingsamtykke"',
      value: "marketing-consent",
      url: "https://dgfs-butik.dk/marketing-consent",
    },
    {
      label:
        'Brugerdefineret link (Linker til ekstern URL, åbnes i et "WebView")',
      value: "custom",
    },
  ];

  componentWillReceiveProps(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    this.setStateFromProps();
  }

  componentDidMount(): void {
    fetch("https://sengrosapi-v2.fsdigital.dk/api/node/theme").then(
      async (data: Response) => {
        const dataJson = await data.json();
        this.themeOptions = dataJson.data.map((x: any) => {
          return { label: x.attributes.title, value: x.id } as any;
        });

        this.setState({
          themeId: this.props.formState.$.theme.value as string,
        });
      }
    );

    fetch("https://sengrosapi-v2.fsdigital.dk/api/product-group").then(
      async (data: Response) => {
        const dataJson = await data.json();
        this.productOfferOptions = dataJson.data.map((x: any) => {
          return {
            label: x.attributes.field_external_id,
            value: x.attributes.name,
          } as any;
        });
        
        
        if(this.props.formState.$.productOffer){
          this.setState({
            productOfferName: this.props.formState.$.productOffer.value as string,
          });
        }
      }
    );

    this.props.formState.validate();
  }

  render() {
    if (!this.state) return;
    let form = this.props.formState.$;
    let linkTypes = this.linkTypes;
    let { theme, productOfferName, linkType, url } = form;
    const themesData = this.themeOptions;
    const productOffersData = this.productOfferOptions;

    return (
      <div>
        <Dropdown
          disabled={this.props.readonly}
          label="Vælg deep link type"
          placeholder="- Vælg"
          fieldState={linkType}
          onChange={() => this.setFieldState()}
          options={_.orderBy(linkTypes, "label")}
        />

        {themesData && linkType.value === "theme" ? (
          <Dropdown
            disabled={this.props.readonly}
            label="Vælg tema til deep link"
            placeholder="- Vælg"
            fieldState={theme}
            onChange={() => this.setFieldState()}
            options={_.orderBy(themesData, "label")}
          />
        ) : null}

        {productOffersData && linkType.value === "product-offer-specific" ? (
          <Dropdown
            disabled={this.props.readonly}
            label="Vælg tilbudsgruppe til deep link"
            placeholder="- Vælg"
            fieldState={productOfferName}
            onChange={() => this.setFieldState()}
            options={_.orderBy(productOffersData, "label")}
          />
        ) : null}

        {url.value || linkType.value === "custom" ? (
          <Input
            disabled={linkType.value !== "custom"}
            label={
              linkType.value !== "custom"
                ? "Forhåndvisning af deep link"
                : "Skriv brugerdefineret URL til deep link. F.eks https://www.dagrofa.dk/"
            }
            type="text"
            fieldState={url}
          />
        ) : null}
      </div>
    );
  }

  private setStateFromProps() {
    let formState = this.props.formState.$;
    if (this.props.formState.$.url.value) {
      let linkType = this.linkTypes.find((x) => x.url === formState.url.value);
      let themeId: any = null;
      if (!linkType) {
        if (formState.url.value.indexOf("/theme") > -1) {
          linkType = this.linkTypes.find((x) => x.value === "theme");
          themeId = formState.url.value.substring(
            formState.url.value.length - 36,
            formState.url.value.length
          );
        } else {
          linkType = this.linkTypes.find((x) => x.value === "custom");
        }
      }
      formState.linkType.value = linkType?.value ?? "";
      formState.theme.value = themeId ?? undefined;

      this.setState({ themeId: themeId });
      this.props.formState.validate();
    }
  }

  private setFieldState() {
    let formState = this.props.formState.$;
    if (!formState.linkType.value || formState.linkType.value === "-1") {
      formState.url.value = null;
      return;
    }
    let url = this.linkTypes.find((x) => x.value === formState.linkType.value)?.url;
    if(formState.theme?.value) url = url?.replace("[theme.id]", formState.theme.value as string);
    if(formState.productOfferName?.value) url = url?.replace("[productOfferName]", formState.productOfferName.value as string);
    formState.url.value = url;
    this.props.formState.validate();
  }
}

export default DeepLinkSelector;
