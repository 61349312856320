import Header from "components/Header";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Parse } from "helpers/Parse";
import { Button, Table } from "rsuite";
import { authStore } from "stores/AuthStore";
import { navigationStore } from "stores/NavigationStore";
import { appLinkStore } from "stores/AppLinkStore";

const { Column, HeaderCell, Cell } = Table;

@observer
class IndexAppLinksPage extends React.Component {
  LINKS_DEFAULT = 50;
  LINKS_MAX = 9999;

  state = {
    getAllClicked: false,
    filterValue: "all",
    sortColumn: "createdAt",
    sortType: "desc",
    isLoading: true,
    data: [],
  };

  handleSortColumn = (sortColumn: any, sortType: any) => {
    this.setState({ sortColumn, sortType, isLoading: true });
    appLinkStore
      .getAll(this.LINKS_DEFAULT, {
        ...this.resolveQueryOptionsState(),
        ...{ sortColumn, sortType },
      })
      .then(() => {
        this.setState({ data: appLinkStore.appLinks, isLoading: false });
      });
  };

  componentDidMount() {
    if (!Parse.User.current()) {
      navigationStore.go("/login");
    }
    appLinkStore
      .getAll(this.LINKS_DEFAULT, this.resolveQueryOptionsState())
      .then(() => {
        this.setState({ data: appLinkStore.appLinks, isLoading: false });
      });
  }

  onRowClicked = async (rowData: any) => {
    let text = rowData.get("firebaseLink");
    try {
      await navigator.clipboard.writeText(text);
      alert("Kopieret til udklipsholder");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  resolveQueryOptionsState() {
    const { sortColumn, sortType, filterValue } = this.state;
    return { sortColumn, sortType, filterValue };
  }

  getAllAppLinks = () => {
    this.setState({ isLoading: true });
    appLinkStore
      .getAll(this.LINKS_MAX, this.resolveQueryOptionsState())
      .then(() => {
        this.setState({
          getAllClicked: true,
          data: appLinkStore.appLinks,
          isLoading: false,
        });
      });
  };

  render() {
    const { getAllClicked, sortColumn, sortType, data, isLoading } = this.state;

    return (
      <div>
        <Header>
          <Button
            color="red"
            onClick={() => navigationStore.go("/app-links/create")}
          >
            Opret nyt app link
          </Button>

          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => authStore.logout()}
          >
            Log ud
          </Button>
        </Header>

        <p>
          Disse links gør det muligt at deep-linke ind i app'en fra eksterne steder såsom marketing mails eller websider.<br /><br />
          
          Efter oprettelse kan du klikke på rækken i tabellen for at kopiere linket til udklipsholder. <br /><br/>
        </p>

        <Table
          data={data}
          rowKey="id"
          rowHeight={90}
          sortColumn={sortColumn}
          sortType={sortType as any}
          onSortColumn={this.handleSortColumn}
          onRowClick={this.onRowClicked}
          loading={isLoading}
          renderEmpty={() =>
            !isLoading ? (
              <div className="rs-table-body-info">Ingen app links</div>
            ) : null
          }
          renderLoading={() => (
            <div className="rs-table-loader-wrapper">
              <div className="rs-table-loader">
                <i className="rs-table-loader-icon" />
                <span className="rs-table-loader-text">
                  Henter app links...
                </span>
              </div>
            </div>
          )}
          autoHeight
        >
          <Column flexGrow={1}>
            <HeaderCell>Navn</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("name");
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Eksternt deep link</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("firebaseLink");
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Internt app deeplink</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("appInternalLink");
              }}
            </Cell>
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>Oprettet</HeaderCell>
            <Cell dataKey="createdAt">
              {(rowData: any) => {
                const dateStr = rowData.get("createdAt").toString();
                if (dateStr)
                  return moment(dateStr).format("DD/MM - YYYY HH:mm");
                else return null;
              }}
            </Cell>
          </Column>
        </Table>
        {!isLoading && data.length > 0 && !getAllClicked && (
          <div style={{ textAlign: "center", margin: "50px 0" }}>
            <Button onClick={this.getAllAppLinks}>Vis alle</Button>
          </div>
        )}
      </div>
    );
  }
}

export default IndexAppLinksPage;
