import { observable, action } from "mobx";
import { Parse } from "helpers/Parse";
import LoadPromise from "helpers/LoadPromise";
import { navigationStore } from "stores/NavigationStore";
import _ from "lodash";
import { removeObjectById } from "helpers/CollectionHelper";

export interface AppLinkFormDataInterface {
  id?: any;
  parseObject?: any;
  name: string;
  appInternalLink: string;
  firebaseDynamicLink: string;
}

export class AppLinkStore {
  @observable appLinks: Array<Parse.Object> = [];
  schemaName = "FirebaseDynamicLink";

  @action
  getAll = (limit: number = 9999, options?: any) => {
    return LoadPromise(async (resolve, reject) => {
      if (!Parse.User.current()) {
        navigationStore.go("/login");
        resolve();
        return;
      }
      const query = new Parse.Query(this.schemaName);

      if (options) {
        if(options.sortColumn) options.sortType === "asc" ? query.ascending(options.sortColumn) : query.descending(options.sortColumn);
      }

      query.limit(limit);
      const res = await query.find();
      this.appLinks = res;
      resolve();
    });
  };

  @action
  get = (id: string) => {
    return LoadPromise(async (resolve, reject) => {
      const existingNotification = _.find(this.appLinks, { id: id });
      if (existingNotification) {
        resolve(existingNotification);
      } else {
        const query = new Parse.Query(this.schemaName);
        const res = await query.get(id);
        if (res) {
          this.appLinks.push(res);
          resolve(res);
        }
      }
    });
  };

  @action
  create = (data: AppLinkFormDataInterface) => {
    return LoadPromise(async (resolve, reject) => {
      const AppLink = Parse.Object.extend(this.schemaName);
      const appLink = new AppLink();
      this._mapToParse(appLink, data);
      const savedObj = await appLink.save();
      this.appLinks.unshift(savedObj);
      resolve(savedObj);
    });
  };

  _mapToParse = (appLink: Parse.Object, data: AppLinkFormDataInterface) => {
    appLink.set("name", data.name);
    appLink.set("appInternalLink", data.appInternalLink);
    return appLink;
  };

  @action
  delete = (id: string) => {
    return LoadPromise(async (resolve, reject) => {
      const query = new Parse.Query(this.schemaName);
      const res = await query.get(id);
      if (res) {
        await res.destroy();
        this.appLinks = removeObjectById(this.appLinks, res);
        resolve();
      } else reject();
    });
  };
}

export const appLinkStore = new AppLinkStore();
